import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import Close from "../image/icons/close-mark.png";
import { apiCalling, awsAPIUrlcommon } from "../apiService";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS from "aws-sdk";
import { method } from "lodash";
import * as XLSX from "xlsx";
import $ from "jquery";
import PurpleLogoImg from "../image/media/purple-health.png"
import imgPrvw from "../image/icons/image-preview.png";
import { MdHeight } from "react-icons/md";

const DoctorDashboardExcelPopup = (props) => {
  const [fileName, setFileName] = useState("")
  const [uploaded, setUploaded] = useState(false)
  const [imageDeatils, setimageDeatils] = useState({
    AWSAccessKeyId: "",
    s3bucketAccessKey: "",
    region: "us-east-1",
    bucket: "",
    bucketFolder: "",
  })
  const [contactName, setContactName] = useState("")
  const [contactMobile, setContactMobile] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const [companyLogo, setCompanyLogo] = useState("")
  const [projectLogo, setProjectLogo] = useState("")
  const [loading, setLoading] = useState(false);
  const [purpleLogoFlag, setPurpleLogoFlag] = useState(false);
  const [imagePathData, setImagePathData] = useState("");


  useEffect(() => {
    imageUploadHandler();
  }, [])
  useEffect(() => {
    if(props.fileData.length != 0){
      setContactName(props.fileData.contact_person_name)
      setContactEmail(props.fileData.contact_person_email)
      setContactMobile(props.fileData.contact_person_phone)
      setPurpleLogoFlag(props.fileData.purple_logo_required)
      setCompanyLogo(props.fileData.top_right_logo)
      setProjectLogo(props.fileData.bottom_right_logo)
      
      const imgCompanyLogo = document.getElementById('companyLogo');
      imgCompanyLogo.src = imagePathData + props.fileData.top_right_logo;
      const imgProjectLogo = document.getElementById('ProjectLogo');
      imgProjectLogo.src = imagePathData + props.fileData.bottom_right_logo;
    }
  }, [imagePathData, props.fileData])

  const imageUploadHandler = () => {
    const apiJson = {
      method: "POST",
      body: JSON.stringify({
        functionName: "imagePath",
      }),
    };

    apiCalling(apiJson).then((data) => {
      if (data.success === "1") {
        setimageDeatils((prev) => ({
          ...prev,
          s3bucketAccessKey: data.configPathDetails[0].s3bucketAccessKey,
          AWSAccessKeyId: data.configPathDetails[0].s3bucketSecretKey,
          bucket: data.configPathDetails[0].PurpleHealthS3bucket,
          bucketFolder: data.configPathDetails[0].S3BucketMainFolder,
        }))
        const bucketPath =
          data.configPathDetails[0].s3BucketRelianceAddressPath +
          "/" +
          data.configPathDetails[0].S3BucketMainFolder +
          "/" +
          "doctorDashboard" +
          "/";
        setImagePathData(bucketPath);
      }
    }).catch((error) => {
      toast.error("An error occurred while getting details");
    });
  };
  const handleFileUpload = (flag, event) => {
    const file = event.target.files[0];
    if (file) {
      // $(".loader").show();
      setLoading(true);
      fileUpload(flag, file);
    } else {
      alert("Please Choose a file for upload");
      return false;
    }
  };
  const fileUpload = (flag, file) => {
    setUploaded(false)
    const S3BucketMainFolder = "";
    let fileName = file.name
    let last = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length);
    if (flag === "excel") {
      fileName = `doctor-dashboard-report-${new Date().getTime()}.${last}`;
      setFileName(fileName)
    }
    if (flag === "company_logo") {
      fileName = `${flag}-${new Date().getTime()}.${last}`;
      setCompanyLogo(fileName)
      const imgPreview = document.getElementById('companyLogo');
      imgPreview.src = URL.createObjectURL(file);
      imgPreview.onload = () => {
        URL.revokeObjectURL(imgPreview.src); // Free memory
      };
    }
    if (flag === "Project_logo") {
      fileName = `${flag}-${new Date().getTime()}.${last}`;
      setProjectLogo(fileName)
      const imgPreview = document.getElementById('ProjectLogo');
      imgPreview.src = URL.createObjectURL(file);
      imgPreview.onload = () => {
        URL.revokeObjectURL(imgPreview.src); // Free memory
      };
    }

    const fullFilePath = S3BucketMainFolder + fileName;
    AWS.config.update({
      accessKeyId: imageDeatils.s3bucketAccessKey,
      secretAccessKey: imageDeatils.AWSAccessKeyId,
      region: imageDeatils.region,
    });

    const bucketParams = {
      Bucket: `${imageDeatils.bucket}/${imageDeatils.bucketFolder}/doctorDashboard`,
    };
    const s3 = new AWS.S3({ apiVersion: "2006-03-01", params: bucketParams });
    s3.upload(
      {
        Key: fullFilePath,
        Body: file,
        ACL: "public-read",
      },
      (err, data) => {
        if (err) {
          alert("There was an error uploading your Excel: ", err.message);
        } else if (data) {
          setUploaded(true)
          // $(".loader").hide();
          setLoading(false);
        }
      }
    );
  };
  const fileSave = () => {
    // const phonePattern = /^[0-9]{10}$/;
    // const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i;
    // if(props.fileData.length != 0) {
    //   if((companyLogo === props.fileData.top_right_logo) &&
    //     (projectLogo === props.fileData.bottom_right_logo) &&
    //     (contactName === props.fileData.contact_person_name) &&
    //     (contactMobile === props.fileData.contact_person_phone) &&
    //     (contactEmail === props.fileData.contact_person_email) &&
    //     (purpleLogoFlag === props.fileData.purple_logo_required)
    //   ) {
    //     toast.warning("No changes have been updated.");
    //     return false
    //   }
    // }
    // else {
    //   if (!fileName) {
    //     toast("Please select file")
    //     return false;
    //   }
    // }
    if (!fileName) {
      toast("Please select file")
      return false;
    }
    // if (!companyLogo && !projectLogo && !fileName && !contactName && !contactMobile && !contactEmail) {
    //   toast("Please fill all field.")
    //   return false;
    // }
    // if (!contactName) {
    //   toast("Please enter Name")
    //   return false;
    // }
    // else if (!email.test(contactEmail)) {
    //   toast("Email id is not valid.")
    //   return false;
    // }
    // else if (!phonePattern.test(contactMobile)) {
    //   toast("Mobile Number is not valid.")
    //   return false;
    // }
    // else if (!companyLogo) {
    //   toast("Please select Company Logo")
    //   return false;
    // }
    // else if (!projectLogo) {
    //   toast("Please select Project Logo")
    //   return false;
    // }
    else {
      let postData = {
        method: "POST",
        body: JSON.stringify({
          functionName: "purpleHealthDashboardFilesSave",
          fileId: props.fileId,  
          fileName: fileName,
          createdOn: moment(new Date()).valueOf(),
          createdBy: window.localStorage.getItem("userId"),
          // topRightLogo: companyLogo,
          // bottomRightLogo: projectLogo,
          // contactPersonName: contactName,
          // contactPersonPhone: contactMobile,
          // contactPersonEmail: contactEmail,
          // purpleLogoRequired: purpleLogoFlag
        })
      }
      awsAPIUrlcommon(postData).then((data) => {
        setTimeout(() => {
          // $(".loader").hide();
          setLoading(false);
        }, 500);
        if (data.success === "1") {
          toast.success("File uploaded successfully")
          dashBoardDataSave(data?.footerLinksData?.result?.fileId);
          clearData()
        }
        else {
          toast.error(data.errorMessage)
          props.setExcelPopupFlag(false)
        }
      }).catch((error) => {
        // $(".loader").hide();
        setLoading(false);
        toast.error("An error occurred while uploading...");
      });
    }

  }
  const dashBoardDataSave = (fileIdSave) => {
    setTimeout(() => {
      props.setExcelPopupFlag(false)
    }, 2000);
    let postData = {
      method: "POST",
      body: JSON.stringify({
        functionName: "dashBoardDataSave",
        fileId: fileIdSave
      })
    }
    awsAPIUrlcommon(postData).then((data) => {
    })
  }

  const clearData = () => {
    setFileName("")
    setCompanyLogo("")
    setProjectLogo("")
    setContactMobile("")
    setContactEmail("")
    setContactName("")
    props.setFileId("")
    props.setFileData([])
    if(props.fileData.length != 0){
      props.fileList("", 0)
    }
    // const companyLogo = document.getElementById('companyLogo');
    // companyLogo.src = imgPrvw

    // const projectLogo = document.getElementById('ProjectLogo');
    // projectLogo.src = imgPrvw
  }
  return (
    <div className="popup-overlay modal_overlay">
      <ToastContainer />
      {loading && <div className="loader d-block"></div>}
      <div className="popup-contentDiv popup-content-padd pt-3">
        <div className="modal-popup">
          <span className="closepopupqual close-popup-top" id="txtCancel" 
          onClick={() => { 
            props.setExcelPopupFlag(false) 
            clearData()
          }}>
            <img src={Close} alt="Close" className="img-fluid" width="20" />
          </span>
          <div className="content" style={{ minHeight: "200px" }}>
            <div className="HosptlMangemntTable">

              <div className="row mb-3">
                <div className="col-md-12 head-hospMangmntList mb-0">Upload Data</div>
              </div>
              <hr></hr>
              {!props.fileId &&
              <div className="row mb-3">
                <div className="col-md-4"><label className="mb-0">Excel File Upload</label></div>
                <div className="col-md-8">
                  <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" accept=".csv,.xlsx,.xls"
                      onChange={(e) => { handleFileUpload("excel", e) }} />
                  </div>
                  {fileName && <div className="text-success pt-2">
                    <small>{fileName}</small>
                  </div>}
                </div>
              </div>
              }
              {/* <div className="row mb-3">
                <div className="col-md-4"><label className="mb-0">Upload Company Logo</label></div>
                <div className="col-md-4">
                  <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" accept=".jpg,.jpeg,.png"
                      onChange={(e) => { handleFileUpload("company_logo", e) }} />
                  </div>
                  <div className="fileUpload-img fileUpload-imgreptop">
                    <img src={imgPrvw} id="companyLogo" width="50" alt="upload"
                      style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                  </div>
                </div>
              </div> */}
              {/* <div className="row mb-3">
                <div className="col-md-4"><label className="mb-0">Upload Project Logo</label></div>
                <div className="col-md-4">
                  <div className="hspinfo-btnupload mb-auto hspinfo-btnupload-w">
                    <span>Upload</span>
                    <input type="file" className="hspinfoInputfieldupload" accept=".jpg,.jpeg,.png"
                      onChange={(e) => { handleFileUpload("Project_logo", e) }} />
                  </div>
                  <div className="fileUpload-img fileUpload-imgreptop">
                    <img src={imgPrvw} id="ProjectLogo" width="50" alt="upload"
                      style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                  </div>
                </div>
              </div> */}
              {/* <div className="row mb-2">
                <div className="col-md-4">
                  <label className="mb-0">contact Person Name<span className="text-danger">*</span></label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={contactName}
                    onChange={(e) => { setContactName(e.target.value) }} />
                </div>
              </div> */}
              {/* <div className="row mb-2">
                <div className="col-md-4">
                  <label className="mb-0">contact Person Email Id<span className="text-danger">*</span></label>
                </div>
                <div className="col-md-8">
                  <input type="text" className="form-control" value={contactEmail}
                    onChange={(e) => { setContactEmail(e.target.value) }} />
                </div>
              </div> */}
              {/* <div className="row mb-2">
                <div className="col-md-4">
                  <label className="mb-0">contact Person Mobile Number<span className="text-danger">*</span></label>
                </div>
                <div className="col-md-8">
                  <input type="number" className="form-control" value={contactMobile}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setContactMobile(e.target.value);
                      }
                    }} />
                </div>
              </div> */}
              
              {/* <div className="row mb-3">
                <div className="col-md-4"><label className="mb-0">Purple Logo </label></div>
                <div className="col-md-4 d-flex">
                  <input
                    type="checkbox"
                    className="checkbox-clr mr-3"
                    checked={purpleLogoFlag}
                    onChange={()=>setPurpleLogoFlag(!purpleLogoFlag)}
                  />
                  <div className="fileUpload-img fileUpload-imgreptop">
                    <img src={PurpleLogoImg} id="Purple Logo" width="80" alt="upload"
                      style={{ objectFit: "contain", height: "auto", objectPosition: "bottom" }} />
                  </div>
                </div>
              </div> */}
              <div className="col-md-12 text-right pl-0 pt-4">
                <input type="submit" className="smssetng-svebtn" value="Save" onClick={() => { fileSave() }}
                  id="saveBtn" />
                <input type="submit" className="smssetng-cancelbtn" value="Cancel"  onClick={() => { 
            props.setExcelPopupFlag(false) 
            clearData()
          }} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDashboardExcelPopup;
